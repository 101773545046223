html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  color: black;
  text-decoration: none;
  transition: 250ms opacity;
}

a:visited {
  color: black;
}

a:hover {
  opacity: 0.7;
}


:root {
  --dark-green: #079569;
  --green: rgb(91, 203, 172);
  --white: white;
  --gray: rgb(80, 80, 80);
  --light-gray: rgb(213, 213, 213);
  --red: #d64540;
  --blue: #0788de;
  --orange: #ee9913;
  /* linear-gradient(to bottom, #EC4C47, #D64540) */
}
